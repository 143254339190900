import { useNavigate } from '@remix-run/react';
import { useMemo } from 'react';
import { Waypoint } from 'react-waypoint';
import { $path } from 'remix-routes';

import {
  type DtoLearnerProfileResponse,
  type DtoLearningGroupMembership,
  type DtoLearningGroupMembershipsResponse,
} from '@lp-lib/api-service-client/public';

import { ErrorMessage } from '../../../../../app/components/ErrorMessage';
import { useListLoader } from '../../../../hooks/useListLoader';
import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { getLogger } from '../../../../logger/logger';
import { apiService } from '../../../../services/api-service';
import { OrganizerUtils } from '../../../../types';
import { DeleteIcon } from '../../../icons/DeleteIcon';
import { Loading } from '../../../Loading';
import { ActionsMenu } from '../Components/ActionMenu';
import { PageHeader } from '../Components/PageHeader';

const logger = getLogger().scoped('learner-memberships');

interface LearnerMembershipsPageProps {
  profile: DtoLearnerProfileResponse;
}

export function LearnerMembershipsPage({
  profile,
}: LearnerMembershipsPageProps) {
  return (
    <div className='flex flex-col w-full text-white max-w-screen-2xl mx-auto relative'>
      <div className='w-full px-4 md:px-8 pt-4 md:pt-8 pb-1'>
        <PageHeader
          title={profile.name || 'Profile'}
          path={$path('/learning/admin/learners/:id/profile', {
            id: profile.uid,
          })}
        />
      </div>
      <div className='pt-4 pb-10 space-y-20'>
        <MembershipsTable profile={profile} />
      </div>
    </div>
  );
}

function MembershipsTable({ profile }: LearnerMembershipsPageProps) {
  const paginator = useMemo(
    () => apiService.learning.getLearnerMemberships(profile.uid),
    [profile.uid]
  );
  const { items, state, error, dao, handleLoadMore, handleRetry } =
    useListLoader<
      DtoLearningGroupMembershipsResponse,
      DtoLearningGroupMembership
    >(paginator, (a, b) => a.id === b.id);
  const handleRemoveMember = useLiveCallback(
    async (groupId: string, userId: string) => {
      try {
        await apiService.learning.removeMemberFromGroup(groupId, userId);
        dao.deleteBy((m) => m.uid === userId && m.groupId === groupId);
      } catch (error) {
        logger.error('failed to remove member', error, {
          targetGroupId: groupId,
          targetUserId: userId,
        });
      }
    }
  );
  const showEmptyMsg =
    state.isDone && !error && items.length === 0 && !paginator.hasMore();
  const canLoadMore = state.isDone && !error && paginator.hasMore();

  return (
    <div className='w-full px-4 md:px-8'>
      <div className='pb-6 flex items-center justify-between'>
        <div>
          <div className='font-bold text-2xl'>Group Memberships</div>
        </div>
      </div>

      <div className='w-full'>
        <div className='hidden md:flex w-full px-4 py-2 items-center text-gray-400 text-xs uppercase font-medium'>
          <div className='flex-1'>Name</div>
          <div className='flex-1'>Assigned On</div>
          <div className='flex-1'>Assigned By</div>
          <div className='w-12'></div>
        </div>
        <div className='flex flex-col mt-4 text-gray-300 gap-2.5'>
          {items.map((m) => (
            <MembershipRowItem
              key={m.id}
              membership={m}
              onRemove={() => handleRemoveMember(m.groupId, m.uid)}
            />
          ))}
        </div>
      </div>

      <div className='w-full mt-8'>
        {state.isRunning && (
          <div className='flex items-center justify-center'>
            <Loading />
          </div>
        )}
        {error && (
          <div className='w-full flex items-center justify-center text-white'>
            <ErrorMessage
              text='Something went wrong'
              handleRetry={handleRetry}
            />
          </div>
        )}
        {showEmptyMsg && (
          <div className='w-full flex items-center justify-center text-icon-gray'>
            No memberships.
          </div>
        )}
        {canLoadMore && (
          <Waypoint onEnter={handleLoadMore} fireOnRapidScroll>
            <div>&nbsp;</div>
          </Waypoint>
        )}
      </div>
    </div>
  );
}

export function MembershipRowItem(props: {
  membership: DtoLearningGroupMembership;
  onRemove: () => void;
}) {
  const { membership, onRemove } = props;
  const navigate = useNavigate();
  const handleClick = useLiveCallback(() => {
    navigate(
      $path('/learning/admin/groups/:id', {
        id: props.membership.groupId,
      })
    );
  });
  const actionSheet = useMemo(() => {
    return (
      <ActionsMenu
        actions={[
          {
            label: 'Remove Member',
            icon: <DeleteIcon className='w-6 h-6 fill-current pr-2' />,
            onClick: onRemove,
            variant: 'danger',
          },
        ]}
        className='w-50'
      />
    );
  }, [onRemove]);

  return (
    <div
      className={`
        relative w-full px-4 py-3
        bg-[#17171A] hover:bg-[#1f1f22] transition-colors
        flex items-center
        border border-lp-gray-003 rounded-lg
        cursor-pointer
      `}
      onClick={handleClick}
    >
      <div className='flex-1 text-white font-medium text-sm truncate'>
        {membership.group?.name || 'Unnamed Group'}
      </div>

      <div className='flex-1 text-white/90 text-sm'>
        {new Date(membership.createdAt).toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        })}
      </div>

      <div className='flex-1 text-white/90 text-sm'>
        {OrganizerUtils.GetDisplayName(membership.addedBy)}
      </div>

      <div className='w-12 flex justify-end'>{actionSheet}</div>
    </div>
  );
}
